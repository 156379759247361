// src/components/About.js
import React from "react";
import pixelAvatar from "../assets/images/Avatar_Pixel.png";
import riseAvatar from "../assets/images/Avatar_Rise.png";

const About = () => {
  return (
    <div className="about-us-container">
      <h1>À propos de nous</h1>
      <div className="character-section">
        <div className="character-info-pixel">
          <img src={pixelAvatar} alt="Pixel" className="character-image"></img>
          <div className="text">
            <h2>Pixel - L&#39;Architecte du Digital</h2>
            <p>
              Pixel est un développeur informatique passionné, qui a toujours
              été fasciné par les technologies et leur capacité à transformer le
              monde des affaires. Après avoir obtenu son diplôme en
              informatique, il a intégré une entreprise où il a joué un rôle clé
              dans la mise en place d&#39;un site e-commerce révolutionnaire. Ce
              projet ambitieux, qui comptait plus de 15 000 références, a attiré
              jusqu&#39;à 100 000 visiteurs par jour, prouvant l&#39;impact
              qu&#39;une présence numérique bien conçue peut avoir sur une
              entreprise.
            </p>
            <p>
              Fort de cette expérience, Pixel a ensuite rejoint une société de
              consulting renommée, où il a collaboré avec des grands groupes
              français et internationaux. Il a accompagné ces entreprises dans
              leur transformation numérique, leur apportant son expertise
              technique et des solutions innovantes adaptées à leurs besoins
              spécifiques. En 2024, alors qu&#39;il se sentait prêt pour un
              nouveau défi, il rencontre Rise, un entrepreneur avec une vision
              similaire. Ensemble, ils décident d&#39;unir leurs forces pour
              aider d&#39;autres entreprises à naviguer dans le monde digital.
            </p>
            <p>
              Pixel est le créateur de ce site, conçu pour partager son
              expérience et fournir des ressources précieuses aux entreprises
              qui souhaitent réussir leur transition digitale. Il est convaincu
              que tout le monde mérite d&#39;accéder aux outils et aux
              connaissances nécessaires pour s&#39;épanouir dans l&#39;ère
              numérique.
            </p>
          </div>
        </div>
        <div className="character-info-rise">
          <div className="text">
            <h2>Rise - L&#39;Entrepreneur Résilient</h2>
            <p>
              Rise a toujours eu un esprit d&#39;entreprise. En 2015, il fonde
              sa première imprimerie, rapidement reconnue pour la qualité de ses
              produits et son service à la clientèle. Sous sa direction,
              l&#39;entreprise a connu une croissance impressionnante,
              embauchant jusqu&#39;à 30 salariés. Cependant, la route du succès
              n&#39;a pas été sans obstacles. Une série d&#39;aléas, tels que
              des retards dans la production et des défis financiers, a
              finalement conduit à la faillite de son entreprise. Au lieu de se
              laisser abattre, Rise a décidé de tirer des leçons de cette
              expérience.
            </p>
            <p>
              En 2024, il rachète une petite imprimerie avec un salarié et, fort
              de son parcours, il se fixe un nouvel objectif : dynamiser son
              entreprise sans croissance apparente grâce à la digitalisation. Sa
              rencontre avec Pixel lui ouvre de nouvelles perspectives sur la
              digitalisation. Rise comprend que pour rester compétitif, il doit
              embrasser le numérique. Ensemble, ils explorent comment utiliser
              les outils digitaux pour transformer son entreprise et améliorer
              ses opérations.
            </p>
            <p>
              Rise s&#39;engage à partager son histoire et ses expériences avec
              d&#39;autres entrepreneurs qui traversent des situations
              similaires. Il croit fermement que, même face à l&#39;adversité,
              il est possible de rebondir et de trouver des solutions
              novatrices.
            </p>
          </div>
          <img
            src={riseAvatar}
            alt="Rise"
            className="character-image character-image-rise"
          />
        </div>
      </div>

      <div className="mission-section">
        <h2>Notre Mission</h2>
        <p>
          Chez <strong>PixelRise</strong>, notre mission est claire :
          accompagner les petites et moyennes entreprises dans leur transition
          digitale. Nous offrons gratuitement un contenu riche et varié,
          permettant aux entreprises de se former et d&#39;apprendre par
          elles-mêmes à naviguer dans ce monde numérique en constante évolution.
          Notre objectif principal est de rendre accessible les ressources et
          les outils nécessaires pour que chaque entreprise, quelle que soit sa
          taille, puisse réussir sa transformation digitale.
        </p>
        <p>
          Nous croyons en la puissance de l&#39;éducation et de
          l&#39;autonomisation. Que vous soyez une entreprise traditionnelle
          comme celle de Rise ou un acteur déjà numérique, nous sommes ici pour
          vous aider à naviguer dans ce nouveau monde digital avec confiance et
          succès.
        </p>
      </div>
      {/*<h3>Pixel - L&#39;Architecte du Digital</h3>
      <p>
        Avec une solide formation en développement informatique, Pixel a
        rapidement fait ses preuves en participant à la mise en place d’un site
        e-commerce comptant plus de 15 000 références et attirant plus de 10 000
        visiteurs par jour. Après ce succès, il a travaillé dans une société de
        consulting auprès de grands groupes français et internationaux,
        apportant ses compétences et son expertise pour répondre aux besoins
        numériques de ses clients. En 2024, Pixel rencontre Rice, un
        entrepreneur visionnaire issu du monde de l&#39;imprimerie. Ensemble,
        décident de s&#39;associer pour digitaliser l&#39;entreprise de Rice.
        Pixel est également le créateur de ce site, conçu pour partager son
        expérience et offrir des solutions concrètes aux entreprises qui
        souhaitent réussir leur transformation digitale.
      </p>
      

      <h3>Rice - L&#39;Entrepreneur</h3>
      <p>
        Rice a démarré sa carrière dans l&#39;imprimerie et a fondé sa première
        entreprise en 2015. Sous sa direction, l&#39;entreprise a embauché
        jusqu&#39;à 30 salariés et a connu une belle croissance.
        Malheureusement, une série d&#39;aléas a mené à la faillite de sa
        société. Ne se laissant pas abattre, Rice a rebondi en 2024 en rachetant
        une petite imprimerie avec un salarié. Son nouvel objectif : dynamiser
        une entreprise stagnante et sans croissance grâce à ses connaissances du
        marchés et à la digitalisation. C&#39;est à ce moment qu&#39;il
        rencontre Pixel, et ensemble, ils décident de transformer son entreprise
        en utilisant des outils digitaux modernes. Grâce à leur collaboration,
        Rice s&#39;engage pleinement dans cette nouvelle aventure, déterminé à
        partager son expérience de résilience et de transformation avec
        d&#39;autres entrepreneurs.
      </p>

      <h3>Notre Mission</h3>
      <p>
        Chez PixelRise, notre objectif est simple : accompagner les petites et
        moyennes entreprises dans leur transition digitale, en leur fournissant
        des solutions sur mesure, pratiques et efficaces. Nous croyons que
        chaque entreprise, quelle que soit sa taille, mérite d&#39;accéder aux
        outils et technologies qui lui permettent de prospérer dans un monde
        numérique. Que vous ayez besoin d&#39;un site web, d&#39;une stratégie
        de marketing numérique ou d&#39;une solution logicielle, nous sommes là
        pour vous accompagner à chaque étape.
      </p>
      */}
    </div>
  );
};

export default About;
