import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      {/*<p>© 2024 PixelRise. Tous droits réservés.</p>
      <ul className="footer-links">
        <li>
          <Link to="/mentions-legales">Mentions Légales</Link>
        </li>
        <li>
          <Link to="/about">Qui sommes nous ?</Link>
        </li>
        <li>
          <Link to="/contact">Contactez-Nous</Link>
        </li>
      </ul>*/}
    </footer>
  );
};

export default Footer;
