import React from "react";

const Services = () => {
  return (
    <div className="services-page">
      <h2>Nos Solutions de Digitalisation</h2>
      <div className="service-details">
        <div>
          <h3>Création de site web / E-commerce</h3>
          <p>
            Nous concevons des sites web optimisés pour vos clients et pour les
            moteurs de recherche.
          </p>
        </div>
        <div>
          <h3>Outils de gestion (CRM, ERP)</h3>
          <p>
            Améliorez la gestion de vos clients, vos stocks, et vos processus
            internes avec des outils performants.
          </p>
        </div>
        <div>
          <h3>Sécurité des données</h3>
          <p>
            Protégez vos données sensibles avec des solutions robustes et
            conformes aux régulations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
