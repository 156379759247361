// src/components/Contact.js
import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const Contact = () => {
  // Déclaration du hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Fonction pour gérer la soumission du formulaire
  const onSubmit = async (data) => {
    try {
      const response = await axios.post("URL_DE_TON_API", data);
      alert("Votre message a été envoyé !");
    } catch (error) {
      console.error("Erreur lors de l&#39;envoi du message", error);
      alert("Une erreur s&#39;est produite. Veuillez réessayer.");
    }
    // Ici, tu peux gérer l'envoi des données à une API ou une base de données.
    alert("Votre message a été envoyé !");
  };

  return (
    <div className="contact">
      <h2>Contactez-nous</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="name">Nom :</label>
          <input
            type="text"
            id="name"
            {...register("name", { required: "Le nom est requis" })}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
        </div>

        <div>
          <label htmlFor="email">Email :</label>
          <input
            type="email"
            id="email"
            {...register("email", {
              required: "L&#39;email est requis",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Format d&#39;email invalide",
              },
            })}
          />
          {errors.email && <p className="error">{errors.email.message}</p>}
        </div>

        <div>
          <label htmlFor="message">Message :</label>
          <textarea
            id="message"
            {...register("message", { required: "Le message est requis" })}
          />
          {errors.message && <p className="error">{errors.message.message}</p>}
        </div>

        <button type="submit" className="button">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default Contact;
