import React from "react";

const Home = () => {
  return (
    <div className="home">
      {/*<section className="banner">
        <h1>
          Boostez la performance de votre entreprise grâce à la digitalisation
        </h1>
        <p>Accompagnement personnalisé pour les TPE et PME</p>
        <button className="button">Découvrir nos solutions</button>
      </section>

      <section className="services-intro">
        <h2>Nos Solutions</h2>
        <div className="services">
          <div className="service-item">
            <h3>Création de site web</h3>
            <p>Conception de sites vitrines et e-commerce</p>
          </div>
          <div className="service-item">
            <h3>Automatisation des processus</h3>
            <p>Optimisez vos opérations internes avec des outils digitaux</p>
          </div>
          <div className="service-item">
            <h3>Marketing digital</h3>
            <p>Augmentez votre visibilité en ligne</p>
          </div>
        </div>
      </section>*/}
    </div>
  );
};

export default Home;
