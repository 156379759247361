import React from "react";

const MentionsLegales = () => {
  return (
    <div className="mentions-legales">
      <h2>Mentions Légales</h2>

      <h3>1. Éditeur du site</h3>
      <p>
        Le site <strong>www.exemple.com</strong> est édité par :
      </p>
      <p>
        <strong>Nom de l&#39;entreprise</strong>
        <br />
        Adresse : 123 Rue Exemple, 75000 Paris, France
        <br />
        Téléphone : 01 23 45 67 89
        <br />
        Email : contact@exemple.com
        <br />
        SIRET : 123 456 789 00010
      </p>

      <h3>2. Hébergeur</h3>
      <p>Le site est hébergé par :</p>
      <p>
        <strong>Nom de l&#39;hébergeur</strong>
        <br />
        Adresse : 456 Rue Hébergeur, 75000 Paris, France
        <br />
        Téléphone : 01 98 76 54 32
      </p>

      <h3>3. Propriété intellectuelle</h3>
      <p>
        Tous les éléments présents sur ce site, y compris les textes, images,
        graphismes, logos, sont protégés par le droit d&#39;auteur et sont la
        propriété de <strong>Nom de l&#39;entreprise</strong> ou de tiers ayant
        autorisé leur utilisation. Toute reproduction ou représentation, en tout
        ou partie, est interdite sans autorisation préalable.
      </p>

      <h3>4. Données personnelles</h3>
      <p>
        Conformément à la loi Informatique et Libertés du 6 janvier 1978 et au
        Règlement Général sur la Protection des Données (RGPD), vous disposez
        d&#39;un droit d&#39;accès, de rectification et de suppression des
        données personnelles vous concernant. Pour exercer ce droit, vous pouvez
        nous contacter à l&#39;adresse suivante :{" "}
        <strong>contact@exemple.com</strong>.
      </p>

      <h3>5. Cookies</h3>
      <p>
        Le site utilise des cookies pour améliorer votre expérience de
        navigation. Vous pouvez gérer vos préférences de cookies à tout moment
        dans votre navigateur.
      </p>

      <h3>6. Loi applicable</h3>
      <p>
        Les présentes mentions légales sont régies par la loi française. Tout
        litige sera soumis à la compétence des tribunaux français.
      </p>
    </div>
  );
};

export default MentionsLegales;
